// 报表相关api
import request from '@/until/request.js'
const prefixName = '/yny/wmsPurchaseReport'

// 采购明细表
export function GetPurchaseReportDetail(data){
    return request({
      url: prefixName + '/detail',
      method: 'get',
      params: { ...data }
    })
  }
  export function GetDetailCombined(data){
    return request({
      url: prefixName + '/detailCombined',
      method: 'get',
      params: { ...data }
    })
  }