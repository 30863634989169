<template>
  <div class="view-wrap">
    <div class="search">
      <el-form :inline="true">
        <el-form-item label="录单日期">
          <el-date-picker
            v-model="dateTime"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            :picker-options="pickerOptions"
            end-placeholder="结束日期"
            :clearable="false"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商" prop="supplierId">
          <el-select v-model="CheckSupplierId" placeholder="全部" multiple>
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品" prop="productId">
          <el-input
            v-if="checkProductName === ''"
            placeholder="请选择商品"
            clearable
            v-model="checkProductName"
            @focus.stop="openProduct"
            @clear="clearProduct"
          ></el-input>
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            :content="checkProductName"
            placement="top-start"
          >
            <el-input
              placeholder="请选择商品"
              clearable
              v-model="checkProductName"
              @focus.stop="openProduct"
              @clear="clearProduct"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="单据类型">
          <el-select v-model="searcForm.type" placeholder="全部">
            <el-option
              v-for="item in TypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库：">
          <el-select v-model="CheckWms" multiple placeholder="全部">
            <el-option
              v-for="item in WmsData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"
            ><i class="el-icon-search" /> 查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="view">
      <el-table
        show-summary
        :summary-method="getSummaries"
        v-loading="loading"
        border
        :data="tableData"
        row-key="id"
      >
        <el-table-column
          align="center"
          v-for="(item, index) in tableItem"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width ? item.width : ''"
        >
          <template slot-scope="scope">
            <div style="text-align: left" v-if="item.prop === 'supplierName'">
              {{
                scope.row[item.prop]
                  ? `${scope.row[item.prop]}(${scope.row.supplierCode})`
                  : "-"
              }}
            </div>
            <div style="text-align: left" v-else-if="item.prop === 'wmsName'">
              {{
                scope.row[item.prop]
                  ? `${scope.row[item.prop]}(${scope.row.wmsCode})`
                  : "-"
              }}
            </div>
            <div style="text-align: right" v-else-if="item.type === 'price'">
              <span v-if="scope.row[item.prop] === undefined"> - </span>
              <span v-else>
                <span v-if="scope.row[item.prop]">
                  {{ scope.row[item.prop] | backPrice }}
                </span>
                <span v-else>{{ "0.00" }}</span>
              </span>
            </div>
            <div v-else style="text-align: left" :show-overflow-tooltip="true">
              {{ scope.row[item.prop] ? scope.row[item.prop] : "-" }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; justify-content: space-between">
        <div></div>
        <el-pagination
          @size-change="changePageSize"
          @current-change="changeCurrentPage"
          :current-page="searcForm.pageNum"
          :page-size="searcForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <div></div>
      </div>
    </div>
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isStatement="true"
          :checkArr="checkProductArr"
          @close="Visible = false"
          @SelectGoods="changeProductId"
        />
      </div>
    </miaDialog>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>
<script>
import SelectGoods from "@/components/pustorageTable/SelectGoods.vue";
import { datatimestatus, GetMouthDate } from "@/until/auth";
import { GetSupplierList } from "@/api/supplier";
import { GetWmsList } from "@/api/warehouse";
import { GetPurchaseReportDetail, GetDetailCombined } from "@/api/statement";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  data() {
    return {
      openAccountFlag: true,
      info: {
        show: false,
      },
      loading: false,
      searcForm: {
        type: 0,
        startDate: "",
        endDate: "",
        supplierIds: "",
        productIds: "",
        wmsIds: "",
        pageNum: 1,
        pageSize: 10,
      },
      dateTime: [],
      CheckWms: [],
      CheckSupplierId: [],
      pickerOptions: null,
      supplierList: [],
      WmsData: [],
      tableData: [],
      Visible: false,
      tableItem: [
        { prop: "entryDate", label: "录单日期", type: "default", width: "180" },
        { prop: "type", label: "单据类型", type: "default", width: "100" },
        { prop: "no", label: "单据号", type: "default", width: "180" },
        {
          prop: "supplierName",
          label: "供应商",
          type: "default",
          width: "200",
        },
        { prop: "wmsName", label: "仓库", type: "default", width: "150" },
        { prop: "productCode", label: "商品编号", type: "default" },
        {
          prop: "productName",
          label: "商品名称",
          type: "default",
          width: "180",
        },
        { prop: "unit", label: "单位", type: "default", width: "80" },
        { prop: "num", label: "数量", type: "default", width: "80" },
        { prop: "price", label: "单价", type: "price" },
        { prop: "totalPrice", label: "金额", type: "price" },
      ],
      TypeList: [
        { label: "全部", value: 0 },
        { label: "采购单", value: 1 },
        { label: "采购退货单", value: 2 },
      ],
      total: 0,
      checkProductName: "",
      checkProductArr: [],
      // 合计数据
      Summation: {},
    };
  },
  components: {
    SelectGoods,
    OpenAccount,
  },
  created() {
    this.getConfigBills();
    this.datatimestatus();
    this.dateTime = GetMouthDate();
    this.getSupplier();
    this.getWmsList();
    this.getDate();
    this.searcForm.startDate = this.dateTime[0];
    this.searcForm.endDate = this.dateTime[1];
  },
  mounted() {
    this.getData();
    this.getSummation();
  },
  filters: {
    backPrice(val) {
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  watch: {
    dateTime(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
    CheckSupplierId(val) {
      this.searcForm.supplierIds = val.join(",");
    },
    CheckWms(val) {
      this.searcForm.wmsIds = val.join(",");
    },
    checkProductArr(val) {
      this.checkProductName =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.name;
              })
              .join("、");
      this.searcForm.productIds =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.id;
              })
              .join(",");
    },
  },
  methods: {
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.dateTime = defaultDate;
      this.getData();
    },
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
          this.openAccountFlag = false;
        }
      }
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    getData() {
      this.loading = true;
      GetPurchaseReportDetail(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records, total, current } = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              this.loading = false;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
          this.loading = false;
        } else {
          this.tableData = [];
          this.loading = false;
        }
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 供应商列表
    getSupplier() {
      GetSupplierList({}).then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data.records;
        } else {
          this.supplierList = [];
        }
      });
    },
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsData = res.data.records;
        } else {
          this.WmsData = [];
        }
      });
    },
    search() {
      this.getData();
      this.getSummation();
    },
    changeSelect() {},
    changeProductId(list) {
      this.Visible = false;
      this.checkProductArr = list;
    },
    openProduct() {
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.SelectGoods.init("选择商品");
      });
    },
    clearProduct() {
      this.checkProductArr = [];
    },
    // 获取合计
    getSummation() {
      GetDetailCombined(this.searcForm).then((res) => {
        if (res.code === 1) {
          this.Summation = res.data;
        }
      });
    },
    // 设置合计列
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (column.property === "num") sums[index] = this.Summation.num;
        if (column.property === "price")
          sums[index] = this.backPrice(this.Summation.price);
        if (column.property === "totalPrice")
          sums[index] = this.backPrice(this.Summation.totalPrice);
      });
      return sums;
    },
    backPrice(val) {
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
};
</script>
、
<style lang="scss" scoped>
.pagination {
  width: 100%;
  text-align: center;
  // margin-top: 10px;
  position: absolute;
  bottom: 20px;
}
::v-deep .el-table__footer-wrapper {
  .el-table__footer {
    td.el-table__cell {
      div {
        white-space: nowrap;
        // 其他自定义样式
        text-align: left;
      }
      &:last-child {
        div {
          white-space: nowrap;
          // 其他自定义样式
          text-align: right;
        }
      }
      &:nth-last-child(2) {
        div {
          white-space: nowrap;
          // 其他自定义样式
          text-align: right;
        }
      }
    }
  }
}
</style>
